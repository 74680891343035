import { FaCheckCircle, FaExclamationCircle, FaClock } from "react-icons/fa";
import { Params, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button/button";
import { PAYMENT_PIX_STATUS } from "../../enums/payment-status.enum";

export default function PaymentStatus() {
  const navigate = useNavigate();
  const { status }: Readonly<Params<string>> = useParams();

  let statusMessage: string = "";
  let icon: JSX.Element = (
    <FaCheckCircle className="text-3xl sm:text-5xl font-semibold text-green-400" />
  );
  let buttonText: string = "Ir para minhas senhas";
  let backgroundColor = "bg-white";
  let borderColor = "border-[#e9e9ee]";
  let textColor = "text-green-400";

  switch (status) {
    case PAYMENT_PIX_STATUS.PAID:
      statusMessage = "Pagamento realizado com sucesso!";
      icon = (
        <FaCheckCircle className="text-3xl sm:text-5xl font-semibold text-green-400" />
      );
      break;
    case PAYMENT_PIX_STATUS.WAITING_PAYMENT:
      statusMessage = "Aguardando pagamento...";
      icon = (
        <FaClock className="text-3xl sm:text-5xl font-semibold text-yellow-400" />
      );
      textColor = "text-yellow-400";
      backgroundColor = "bg-yellow-50";
      break;
    case PAYMENT_PIX_STATUS.PENDING_REFUND:
      statusMessage = "Reembolso pendente...";
      icon = (
        <FaExclamationCircle className="text-3xl sm:text-5xl font-semibold text-orange-400" />
      );
      textColor = "text-orange-400";
      backgroundColor = "bg-orange-50";
      break;
    case PAYMENT_PIX_STATUS.REFUNDED:
      statusMessage = "Pagamento reembolsado!";
      icon = (
        <FaExclamationCircle className="text-3xl sm:text-5xl font-semibold text-red-400" />
      );
      textColor = "text-red-400";
      backgroundColor = "bg-red-50";
      break;
    case PAYMENT_PIX_STATUS.WITH_ERROR:
      statusMessage = "Erro no processamento do pagamento!";
      icon = (
        <FaExclamationCircle className="text-3xl sm:text-5xl font-semibold text-red-500" />
      );
      textColor = "text-red-500";
      backgroundColor = "bg-red-100";
      break;
    case PAYMENT_PIX_STATUS.FAILED:
      statusMessage = "Pagamento falhou!";
      icon = (
        <FaExclamationCircle className="text-3xl sm:text-5xl font-semibold text-red-500" />
      );
      textColor = "text-red-500";
      backgroundColor = "bg-red-100";
      break;
    default:
      statusMessage = "Status desconhecido";
      icon = (
        <FaExclamationCircle className="text-3xl sm:text-5xl font-semibold text-gray-400" />
      );
      textColor = "text-gray-400";
      backgroundColor = "bg-gray-50";
      break;
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center gap-y-4 bg-[#f4f7f9]">
      <div
        className={`flex flex-col justify-center items-center w-[330px] sm:w-[440px] py-10 gap-y-6 ${backgroundColor} rounded-md border-2 ${borderColor}`}
      >
        {icon}
        <h2 className={`text-1xl sm:text-2xl font-semibold ${textColor}`}>
          {statusMessage}
        </h2>
      </div>
      <Button onClick={() => navigate("/my-passwords")}>
        <p className="text-white">{buttonText}</p>
      </Button>
    </div>
  );
}
