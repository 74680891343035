/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes } from "react-router-dom";

// LAYOUTS
import Layout from "./layouts/primary";

// HEADERS PAGES
import HomeHeaderPage from "./pages/Home/home-header";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// PAGES
import HomePage from "./pages/Home/home";
import BuyPasswordPage from "./pages/buy-password/buy-password";
import CheckoutPage from "./pages/checkout/checkout";
import LoginPage from "./pages/auth/login/login";
import RegisterPage from "./pages/auth/register/register";
import MyPasswordsPage from "./pages/MyPasswords/my-passwords";
import { useAuth } from "./services/contexts/AuthContext";
import { useEffect, useState } from "react";
import Loadind from "./components/Loading/loading";
import TimedMessage from "./components/Message/message";
import { useMessage } from "./services/contexts/MessageContext";
import { useLoading } from "./services/contexts/LoadingContext";
import ResumePage from "./pages/resume/resume";
import Events from "./pages/events/events";
import PaymentStatus from "./pages/payment-status/payment-status";
import QuemSomosPage from "./pages/quem-somos/quem-somos";
import ComoFuncionaPage from "./pages/como-funciona/como-funciona";
import PoliticaDePrivacidadePage from "./pages/politica-de-privacidade/politica-de-privacidade";
import PoliticaDeCancelamentoEReembolsoPage from "./pages/politica-de-cancelamento-e-reembolso/politica-de-cancelamento-e-reembolso";
import CheckoutCreditCardPage from "./pages/checkout-credit-card/checkout-credit-card";

function App() {
  // const { load } = useLoading();
  const { message, type } = useMessage();
  const { isLoggedIn } = useAuth();
  const load = false;

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      {load ? (
        <Loadind />
      ) : (
        <Routes>
          <Route
            path="/"
            element={<Layout header={<HomeHeaderPage />} body={<HomePage />} />}
          />
          <Route
            path="/buy-password/:id"
            element={<Layout body={<BuyPasswordPage />} />}
          />
          <Route path="/resume" element={<Layout body={<ResumePage />} />} />
          <Route path="/events" element={<Events />} />
          <Route path="/auth/login" element={<LoginPage />} />
          <Route path="/auth/register" element={<RegisterPage />} />
          <Route
            path="/my-passwords"
            element={<Layout body={<MyPasswordsPage />} />}
          />
          <Route
            path="/checkout"
            element={<Layout body={<CheckoutPage />} />}
          />
          <Route
            path="/checkout-credit-card"
            element={<Layout body={<CheckoutCreditCardPage />} />}
          />
          <Route path="/payment-status/:status" element={<PaymentStatus />} />
          <Route path="/quem-somos" element={<QuemSomosPage />} />
          <Route path="/como-funciona" element={<ComoFuncionaPage />} />
          <Route
            path="/politica-de-privacidade"
            element={<PoliticaDePrivacidadePage />}
          />
          <Route
            path="/politica-de-cancelamento-e-reembolso"
            element={<PoliticaDeCancelamentoEReembolsoPage />}
          />
        </Routes>
      )}
      {message && <TimedMessage message={message} type={type} />}
      <ToastContainer />
    </>
  );
}

export default App;
