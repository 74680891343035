import React from "react";
import { LayoutStyled } from "./styled";

// COMPONENTS
import Footer from "../components/footer/footer";
import ProfileMenu from "../components/profile/profile";
import { FaWhatsapp } from "react-icons/fa";

type LayoutType = {
  header?: React.ReactElement;
  body: React.ReactElement;
};

const Layout: React.FC<LayoutType> = ({ body, header }) => {
  const phoneNumber = "38998725240";
  const message = "Olá! Gostaria de mais informações.";

  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank"); // Abre em uma nova aba
  };

  return (
    <LayoutStyled header={!!header}>
      <header>
        <ProfileMenu />
        {header}
      </header>
      <section className="pb-4">{body}</section>
      <div className="flex flex-1" />
      <footer className="w-full bg-white border-t border-zinc-200 mt-6">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full mx-auto max-w-[1260px] gap-4 py-4">
          <div className="w-[200px] flex flex-col text-zinc-500 gap-y-1">
            <a href="/quem-somos">Quem somos</a>
            <a href="/como-funciona">Como funciona</a>
          </div>
          <div className="w-[200px] flex flex-col text-zinc-500 gap-y-1">
            <a href="/politica-de-privacidade">Política de privacidade</a>
            <a href="/politica-de-cancelamento-e-reembolso">
              Política de cancelamento e reembolso
            </a>
          </div>
          <div className="w-[250px] flex flex-col text-zinc-800">
            <p>© MSENHAS TECNOLOGIAS LTDA</p>
            <p>CNPJ: 55.176.401/0001-99</p>
          </div>
        </div>
      </footer>
      <div
        className="fixed bottom-4 right-4 rounded-full hover:cursor-pointer p-2 bg-green-400"
        role="button"
        onClick={handleClick}
      >
        <FaWhatsapp className="text-3xl text-white" />
      </div>
    </LayoutStyled>
  );
};

export default Layout;
