import { useEffect, useState } from "react";
import { FaCreditCard } from "react-icons/fa";
import { FaPix } from "react-icons/fa6";
import { getEvent } from "../../services/http/events/get-event";
import Spinner from "../../components/Spinner/spinner";
import { formatSelectDay } from "../../utils/format-select-day";
import { formatCurrency } from "../../utils/format-currency";
import { useNavigate } from "react-router-dom";
import { createPaymentPix } from "../../services/http/payment/create-payment-pix";
import { getMeProfile } from "../../services/http/users/get-me-profile";
import { getItem, setItem } from "../../services/storage/storage";
import { createMultiplePassword } from "../../services/http/passwords/get-all-my";

export default function ResumePage() {
  const [eventData, setEventData] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [quantities, setQuantities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [generatingPayment, setGeneratingPayment] = useState(false);
  const [boiNaTvSelection, setBoiNaTvSelection] = useState<{
    [key: number]: boolean;
  }>({});
  const [total, setTotal] = useState<number>();
  const navigate = useNavigate();

  const passwordData: any = getItem("@password-data");

  function calculateTotal(
    quantity: Array<{ quant_id: number; quant_value: number }>
  ) {
    let value = 0;

    quantity.forEach((element) => {
      value += element.quant_value;
    });

    Object.values(boiNaTvSelection).forEach((selected) => {
      if (selected) {
        value += 30;
      }
    });

    setTotal(value);
  }

  async function getData() {
    setLoading(true);
    try {
      const response = await getEvent(passwordData.passwords[0].posterId!);
      setEventData(response);
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getProfile() {
    setLoading(true);
    try {
      const response = await getMeProfile();
      setProfile(response);
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCreatePassword(body: any) {
    try {
      const response = await createMultiplePassword(body);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCreatePaymentPix() {
    setGeneratingPayment(true);

    const area = profile?.usr_phone.substring(0, 2);
    const number = profile?.usr_phone.substring(2);

    // * BOI NA TV
    // const updatedPasswords = passwordData?.passwords?.map(
    //   (password: any, index: number) => ({
    //     ...password,
    //     bull_on_tv: boiNaTvSelection[index] || false,
    //   })
    // );

    // const updatedPasswordData = {
    //   ...passwordData,
    //   passwords: updatedPasswords,
    // };

    // setItem("@password-data", updatedPasswordData);

    const itemsWithFee = [
      ...quantities
        .map((item, index) => [
          {
            amount: item.quant_value * 100,
            description: "Senha",
            quantity: 1,
          },
          boiNaTvSelection[index] && {
            amount: 3000,
            description: "Boi na TV",
            quantity: 1,
          },
        ])
        .flat()
        .filter(Boolean),
      {
        amount: 400,
        description: "Taxa Administrativa",
        quantity: 1,
      },
    ];

    const body = {
      customer: {
        name: profile?.usr_name,
        email: profile?.usr_email,
        type: "individual",
        document: profile?.usr_cpf,
        phones: {
          home_phone: {
            country_code: "55",
            number,
            area_code: area,
          },
        },
      },
      items: itemsWithFee,
      payments: [
        {
          payment_method: "pix",
          pix: {
            expires_in: "1800",
            additional_information: [
              {
                name: "Senha",
                value: "1",
              },
            ],
          },
        },
      ],
    };
    try {
      const response = await createPaymentPix(body as any);
      if (response.status !== "failed") {
        if (response.id) {
          const passData = passwordData.passwords.map((password: any) => ({
            ...password,
            pass_status: response.id,
          }));
          const updatedPasswordData = {
            ...passwordData,
            passwords: passData,
          };
          handleCreatePassword(updatedPasswordData);
          setItem("@pix-payment-response", response);
          navigate("/checkout");
          return;
        }
      }
      return;
    } catch (error) {
      console.log("Error checking job:", error);
      return false;
    } finally {
      setGeneratingPayment(false);
    }
  }

  function handleBoiNaTvChange(index: number) {
    setBoiNaTvSelection((prev) => {
      const updatedSelection = { ...prev, [index]: !prev[index] };

      let value = 0;
      quantities.forEach((element) => {
        value += element.quant_value;
      });

      Object.values(updatedSelection).forEach((selected) => {
        if (selected) {
          value += 30;
        }
      });

      setTotal(value);

      return updatedSelection;
    });
  }

  useEffect(() => {
    if (passwordData) {
      getData();
      getProfile();
      const quantities = passwordData.passwords.flatMap((p: any) =>
        p.quantity.map((q: any) => q)
      );
      setQuantities(quantities);
      calculateTotal(quantities);
    }
  }, []);

  return (
    <div className="border border-[#ace6ff] bg-white -mt-[4em] rounded-[10px] p-4">
      {loading ? (
        <div className="mb-[2em]">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-x-2">
            <span className="text-xl font-medium text-slate-800">
              Resumo do pedido
            </span>
          </div>
          <div className="w-full h-[1px] bg-zinc-300 mt-5" />
          <div className="mt-4">
            <span className="text-slate-800">
              Suas senhas para:{" "}
              <strong className="text-blue-primary">
                {eventData?.post_name}
              </strong>
            </span>
          </div>
          <div className="flex flex-col gap-y-4 mt-4">
            {passwordData?.passwords.map((item: any, index: any) => (
              <div
                key={index}
                className="border border-[#ace6ff] p-3 rounded-md"
              >
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">Senha:</span>
                  <span className="text-slate-800 ml-1">
                    Número {item.number} - {formatSelectDay(item.day.day_index)}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">Vaqueiro:</span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.pass_name_people}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Cavalo puxador:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.pulling_horse}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Esteireiro:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.steerer}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Cavalo esteireiro:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.steer_horse}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">
                    Representação:
                  </span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.representation}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-slate-800 font-medium">Categoria:</span>
                  <span className="text-slate-800 ml-1">
                    {passwordData.passwords[0].category.categ_name}
                  </span>
                </div>
                <div className="flex items-center">
                  {item.quantity.map((q: any) => (
                    <div key={q.quant_id} className="flex items-center">
                      <span className="text-slate-800 font-medium">
                        Valor da senha:
                      </span>
                      <span className="text-slate-800 ml-1">
                        {formatCurrency(q.quant_value)}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex items-center gap-x-2">
                  <span className="text-slate-800 font-medium">
                    Boi na TV - compre por aqui 👉{" "}
                    <a
                      href="https://pay.kirvano.com/71761d51-194b-48a6-8011-731bbb080293"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      Boi na TV
                    </a>
                    <span className="text-slate-800 font-normal"> R$30,00</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <div className="mt-4">
              <span className="text-slate-800 font-medium">
                Total + Taxa administrativa:{" "}
                {total ? formatCurrency(total + 4) : "R$ 0,00"}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-2 sm:gap-x-2 sm:flex-row sm:items-center mt-4">
            <div
              className={`w-[158px] px-3 py-2 flex items-center gap-x-2 rounded-md bg-green-400 
                  ${
                    generatingPayment
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:cursor-pointer"
                  }`}
              onClick={!generatingPayment ? handleCreatePaymentPix : undefined}
              role="button"
            >
              <FaPix className="text-lg text-white" />
              {generatingPayment ? (
                <span className="text-white">Gerando...</span>
              ) : (
                <span className="text-white">Pagar com PIX</span>
              )}
            </div>
            <div
              className="w-[256px] px-3 py-2 flex items-center gap-x-2 rounded-md hover:cursor-pointer bg-blue-400"
              onClick={() => navigate("/checkout-credit-card")}
            >
              <FaCreditCard className="text-lg text-white" />
              <span className="text-white">Pagar com cartão de crédito</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
