import { ChangeEvent, useState } from "react";
import Button from "../../../components/Button/button";
import { formatCurrency } from "../../../utils/format-currency";

interface StepTwoProps {
  secondForm: {
    cardNumber: string;
    cardName: string;
    expiryDate: string;
    cvv: string;
    installments: string;
  };
  setSecondForm: React.Dispatch<
    React.SetStateAction<StepTwoProps["secondForm"]>
  >;
  setFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  addMessage: (message: string, type: "error" | "success" | "info") => void;
  handleFinishPayment: VoidFunction;
  loading: boolean;
  total: number;
}

const Bollet = () => {
  return <div className="w-1.5 h-1.5 rounded-full bg-red-800" />;
};

export function StepTwo({
  secondForm,
  setSecondForm,
  setFirstStep,
  addMessage,
  handleFinishPayment,
  loading,
  total,
}: StepTwoProps) {
  const [errors, setErrors] = useState<any>();

  function validationForm() {
    let errors_list = {};

    if (!secondForm.cardNumber) {
      errors_list = {
        ...errors_list,
        cardNumber: "Número do cartão é obrigatório",
      };
    }
    if (!secondForm.cardName) {
      errors_list = {
        ...errors_list,
        cardName: "Nome no cartão é obrigatório",
      };
    }
    if (!secondForm.expiryDate) {
      errors_list = {
        ...errors_list,
        expiryDate: "Data de validade é obrigatória",
      };
    }
    if (!secondForm.cvv) {
      errors_list = { ...errors_list, cvv: "CVV é obrigatório" };
    }

    setErrors(errors_list);
    return errors_list;
  }

  function handleNextForm() {
    const validate = validationForm();
    if (Object.keys(validate).length !== 0) {
      addMessage("Alguns erros foram encontrados", "error");
      return;
    }
    handleFinishPayment();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputName === "expiryDate") {
      inputValue = inputValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d{2})/, "$1/$2")
        .slice(0, 5);
    }

    if (inputName === "cardNumber") {
      inputValue = inputValue
        .replace(/\D/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim()
        .slice(0, 19);
    }
    setSecondForm({ ...secondForm, [inputName]: inputValue });
  }

  return (
    <>
      <div className="mt-4" />
      <h3 className="text-lg font-medium text-slate-800">Pagamento</h3>
      <form className="mt-4">
        <div className="w-full">
          <label className="text-zinc-800">Quantidade de parcelas</label>
          <input
            value={`1x de ${formatCurrency(total)}`}
            name="cardNumber"
            type="text"
            disabled
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none"
          />
        </div>

        <div className="w-full mt-4">
          <div className="flex items-center gap-x-1">
            <label className="text-zinc-800">Número do Cartão</label>
            <Bollet />
          </div>
          <input
            value={secondForm.cardNumber}
            onChange={handleChange}
            name="cardNumber"
            type="text"
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none"
          />
          {errors?.cardNumber && (
            <span className="mt-8 text-red-500">{errors.cardNumber}</span>
          )}
        </div>

        <div className="w-full mt-4">
          <div className="flex items-center gap-x-1">
            <label className="text-zinc-800">Nome no Cartão</label>
            <Bollet />
          </div>
          <input
            value={secondForm.cardName}
            onChange={handleChange}
            name="cardName"
            type="text"
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none"
          />
          {errors?.cardName && (
            <span className="mt-8 text-red-500">{errors.cardName}</span>
          )}
        </div>

        <div className="flex flex-col sm:flex-row gap-6 mt-4">
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">Validade</label>
              <Bollet />
            </div>
            <input
              value={secondForm.expiryDate}
              onChange={handleChange}
              name="expiryDate"
              type="text"
              placeholder="MM/AA"
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none"
            />
            {errors?.expiryDate && (
              <span className="mt-8 text-red-500">{errors.expiryDate}</span>
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">CVV</label>
              <Bollet />
            </div>
            <input
              value={secondForm.cvv}
              onChange={handleChange}
              name="cvv"
              type="password"
              maxLength={3}
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none"
            />
            {errors?.cvv && (
              <span className="mt-8 text-red-500">{errors.cvv}</span>
            )}
          </div>
        </div>
      </form>
      <div className="flex justify-end items-center gap-x-2 mt-4">
        <Button onClick={() => setFirstStep(true)}>
          <div className="w-full flex justify-center items-center gap-x-2">
            <span className="text-white">Voltar</span>
          </div>
        </Button>

        <div
          className={`px-3 py-2 flex items-center rounded-md bg-blue-primary ${
            loading ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer"
          }`}
          onClick={!loading ? handleNextForm : undefined}
          role="button"
        >
          {loading ? (
            <span className="font-medium text-white">Finalizando...</span>
          ) : (
            <span className="font-medium text-white">Finalizar pagamento</span>
          )}
        </div>
        {/* <Button onClick={handleNextForm} disabled={loading} className="ml-4">
          <div className="w-full flex justify-center items-center gap-x-2">
            {loading ? (
              <span className="text-white"></span>
            ) : (
              <span className="text-white"></span>
            )}
          </div>
        </Button> */}
      </div>
    </>
  );
}
