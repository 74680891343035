import { ViewStyled } from "./styled";

// COMPONENTS
import Card from "./components/card/card";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOne } from "./view.services";
import { PosterOneType } from "../../types/poster";
import { DateData } from "../../configs/data";
import { IoTicketOutline } from "react-icons/io5";
import { formatCPF } from "../../services/utils/format";
import { useMessage } from "../../services/contexts/MessageContext";
import Spinner from "../../components/Spinner/spinner";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { formatSelectDay } from "../../utils/format-select-day";
import { FaChevronDown } from "react-icons/fa";
import { setItem } from "../../services/storage/storage";
import { formatToCEP } from "brazilian-values";
import { redirectAfterLogin } from "../../constants/storage-keys";
import { formatCurrency } from "../../utils/format-currency";

type ResultType = {
  code: number;
  number: number;
  posterId: number;
  category: {
    categ_id: number;
    categ_name: string;
  };
  day: {
    day_id: number;
    day_index: number;
  };
  quantity: Array<{
    quant_id: number;
    quant_value: number;
  }>;
};

const HomePage = () => {
  const { addMessage } = useMessage();
  const [errors, setErrors] = useState<any>();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [endView, setEndView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PosterOneType | null>(null);
  const [selectCategory, setSelectCategory] = useState(0);
  const [selectDay, setSelectDay] = useState(0);
  const [results, setResults] = useState<Array<ResultType>>([]);
  const [selectedNumber, setSelectedNumber] = useState<number>(0);
  const [form, setForm] = useState({
    pass_name_people: "",
    pass_name_people_presentation: "",
    pass_cpf_people: "",
    pass_state_people: "",
    pass_city_people: "",
    pass_postal_code_people: "",
    pass_locality_people: "",
    pass_complement_people: "",
    pass_street_people: "",
    pass_number_people: "",
    pulling_horse: "",
    steer_horse: "",
    steerer: "",
    representation: "",
  });

  async function getEvent() {
    setIsLoading(true);
    try {
      const response = await getOne(id);
      setData(response);
    } catch (error) {
      console.log(error);
      addMessage("Erro ao buscar vaquejada", error as any);
      window.location.href = "/";
    } finally {
      setIsLoading(false);
    }
  }

  function generateList(start: number, end: number): number[] {
    const list = [];

    for (let i = start; i <= end; i++) {
      list.push(i);
    }

    return list;
  }

  function handleChangeCategory(index: string) {
    setSelectCategory(parseInt(index));
    setSelectDay(0);
    setResults([]);
  }

  const handleChangeDay = (index: string) => {
    setSelectDay(parseInt(index));
    setResults([]);
  };

  // TODO - Mudar esse lógica depois
  function handleGoToSignIn() {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.setItem(redirectAfterLogin, window.location.pathname);

      navigate("/auth/login");
      return;
    }
  }

  function handleSelectNumber(
    index: number,
    posterId: number,
    category: any,
    day: any,
    quantity: any
  ) {
    handleGoToSignIn();

    const alreadySelected = results.some((res) => res.number === index);

    if (alreadySelected) {
      setResults((prevResults) =>
        prevResults.filter((res) => res.number !== index)
      );
      return;
    }

    const maxSelections = quantity.length;

    if (results.length >= maxSelections) {
      addMessage(
        `Você só pode selecionar até ${maxSelections} senha(s) para este dia.`,
        "error"
      );
      return;
    }

    const code = Date.now();

    setResults((prevResults) => [
      ...prevResults,
      {
        code,
        number: index,
        quantity: [quantity[prevResults.length % quantity.length]],
        posterId,
        category: {
          categ_id: category.categ_id,
          categ_name: category.categ_name,
        },
        day: {
          day_id: day.day_id,
          day_index: day.day_index,
        },
      },
    ]);
  }

  const getTotal = () => {
    let calc = 0;

    results.forEach((res) => {
      res.quantity.forEach((quant) => {
        calc += quant.quant_value;
      });
    });

    return calc;
  };

  const calculateQuantity = (
    quantity: Array<{ quant_id: number; quant_value: number }>
  ): string => {
    let value = 0;

    quantity.forEach((element) => {
      value += element.quant_value;
    });

    return `R$${value}`;
  };

  function handleChange(event: any) {
    const inputName = event.target.name;
    let inputvalue = event.target.value;

    if (inputName === "pass_cpf_people") {
      inputvalue = formatCPF(inputvalue);
    }

    if (inputName === "pass_postal_code_people") {
      inputvalue = formatToCEP(inputvalue);
    }

    setForm({ ...form, [inputName]: inputvalue });
  }

  const validation = () => {
    let errors_list = {};

    if (!form.pass_name_people.trim()) {
      errors_list = { ...errors_list, pass_name_people: "Nome é obrigatório" };
    }
    if (!form.pass_name_people_presentation.trim()) {
      errors_list = {
        ...errors_list,
        pass_name_people_presentation: "Nome de apresentação é obrigatório",
      };
    }
    if (!form.pass_cpf_people.trim()) {
      errors_list = { ...errors_list, pass_cpf_people: "CPF é obrigatório" };
    }
    if (!form.pulling_horse.trim()) {
      errors_list = {
        ...errors_list,
        pulling_horse: "Nome do cavalo obrigatório",
      };
    }
    if (!form.steer_horse.trim()) {
      errors_list = {
        ...errors_list,
        steer_horse: "Nome do cavalo obrigatório",
      };
    }
    if (!form.steerer.trim()) {
      errors_list = {
        ...errors_list,
        steerer: "Nome do esteireiro obrigatório",
      };
    }
    if (!form.representation.trim()) {
      errors_list = {
        ...errors_list,
        representation: "Representação obrigatório",
      };
    }
    // if (!form.pass_city_people.trim()) {
    //   errors_list = {
    //     ...errors_list,
    //     pass_city_people: "Cidade é obrigatório",
    //   };
    // }
    // if (!form.pass_state_people.trim()) {
    //   errors_list = {
    //     ...errors_list,
    //     pass_state_people: "Estado é obrigatório",
    //   };
    // }
    // if (!form.pass_postal_code_people.trim()) {
    //   errors_list = {
    //     ...errors_list,
    //     pass_postal_code_people: "CEP é obrigatório",
    //   };
    // }
    // if (!form.pass_locality_people.trim()) {
    //   errors_list = {
    //     ...errors_list,
    //     pass_locality_people: "Bairro é obrigatório",
    //   };
    // }
    // if (!form.pass_complement_people.trim()) {
    //   errors_list = {
    //     ...errors_list,
    //     pass_complement_people: "Complemento é obrigatório",
    //   };
    // }
    // if (!form.pass_street_people.trim()) {
    //   errors_list = { ...errors_list, pass_street_people: "Rua é obrigatório" };
    // }
    // if (!form.pass_number_people.trim()) {
    //   errors_list = {
    //     ...errors_list,
    //     pass_number_people: "Número é obrigatório",
    //   };
    // }

    setErrors(errors_list);
    return errors_list;
  };

  function handleProgressSubmit() {
    if (results.length === 0) {
      return addMessage(
        "Pelo menos uma senha deve ser selecionada antes de prosseguir",
        "error"
      );
    }

    // const quantityLength =
    //   data?.category[selectCategory].day[selectDay].quants.length;

    // if (quantityLength && results.length > quantityLength) {
    //   addMessage(
    //     `Você não pode escolher mais de ${
    //       quantityLength < 2
    //         ? `${quantityLength} senha`
    //         : `${quantityLength} senhas`
    //     } `,
    //     "error"
    //   );
    //   return;
    // }

    setEndView(true);
  }

  async function onSubmit() {
    const validate = validation();

    if (!(Object.keys(validate).length === 0)) {
      addMessage("Alguns errors foram encontrados", "error");
      return;
    }

    function formatCEP(cep: string) {
      return cep.replace(/\D/g, "");
    }

    const body = {
      pass_name_people: form.pass_name_people,
      pass_name_people_presentation: form.pass_name_people_presentation,
      pass_cpf_people: form.pass_cpf_people,
      pulling_horse: form.pulling_horse,
      steerer: form.steerer,
      steer_horse: form.steer_horse,
      representation: form.representation,
      address: {
        pass_country_people: "BRA",
        pass_postal_code_people: formatCEP("65043-790"),
        pass_state_people: "MA",
        pass_city_people: "São Luís",
        pass_locality_people: "Coroadinho",
        pass_complement_people: "Próximo a quadra de esportes",
        pass_street_people: "Rua Marfin",
        pass_number_people: "557",
      },
      // ! VER O QUE FAZER COM ESSES DADOS
      // address: {
      //   pass_country_people: "BRA",
      //   pass_postal_code_people: formatCEP(form.pass_postal_code_people),
      //   pass_state_people: form.pass_state_people,
      //   pass_city_people: form.pass_city_people,
      //   pass_locality_people: form.pass_locality_people,
      //   pass_complement_people: form.pass_complement_people,
      //   pass_street_people: form.pass_street_people,
      //   pass_number_people: form.pass_number_people,
      // },
      passwords: results as any,
    };

    setItem("@password-data", body);

    navigate("/resume");
  }

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <>
      <ViewStyled>
        {isLoading ? (
          <div className="pb-9">
            <Spinner />
          </div>
        ) : (
          <>
            {data && (
              <div className="">
                <Card
                  body={{
                    post_id: data.post_id,
                    post_name: data.post_name,
                    post_date: data.post_date,
                    post_city: data.post_city,
                    post_state: data.post_state,
                    organizerId: data.organizerId,
                    post_capa: data.post_capa,
                    post_createdAt: data.post_createdAt,
                    post_updatedAt: data.post_updatedAt,
                  }}
                />
              </div>
            )}
            {!endView && (
              <>
                <div className="flex flex-wrap mt-4 gap-4">
                  <div className="flex flex-col">
                    <label className="text-slate-600 font-medium mb-2">
                      Categoria
                    </label>
                    <div className="flex justify-center relative min-w-[250px] h-[50px]">
                      <select
                        className="w-full appearance-none pr-[30px] pl-[15px] bg-transparent rounded-md border focus:outline-none text-blue-primary border-blue-primary z-10"
                        onChange={(e) => handleChangeCategory(e.target.value)}
                      >
                        {data &&
                          data.category &&
                          data.category.map((item, index) => (
                            <option key={index} value={index}>
                              {item.categ_name}
                            </option>
                          ))}
                      </select>
                      <div className="absolute flex justify-center items-center w-[50px] h-full right-0">
                        <FaChevronDown className="text-blue-primary" />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-slate-600 font-medium mb-2">Dia</span>
                    <div className="flex justify-center relative min-w-[250px] h-[50px]">
                      <select
                        className="w-full appearance-none pr-[30px] pl-[15px] bg-transparent rounded-md border focus:outline-none text-blue-primary border-blue-primary z-10"
                        onChange={(e) => handleChangeDay(e.target.value)}
                      >
                        {data &&
                          data.category[selectCategory].day &&
                          data.category[selectCategory].day.map(
                            (item, index) => (
                              <option key={index} value={index}>
                                {formatSelectDay(item.day_index)}
                              </option>
                            )
                          )}
                      </select>
                      <div className="absolute flex justify-center items-center w-[50px] h-full right-0">
                        <FaChevronDown className="text-blue-primary" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <p className="text-slate-600 font-medium">
                    Escolha suas senhas:
                  </p>
                  <div className="flex flex-wrap mt-2 gap-3">
                    <div className="flex items-center gap-x-2">
                      <div className="w-4 h-4 rounded-[4px] border border-[#0082BA]" />
                      <p className="text-slate-600">Disponíveis para compra</p>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <div className="w-4 h-4 rounded-[4px] bg-[#0082BA]" />
                      <p className="text-slate-600">Selecionado</p>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <div className="w-4 h-4 rounded-[4px] bg-[#FF0000]" />
                      <p className="text-slate-600">Compradas</p>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <p className="text-slate-600 font-medium">
                    Quantidade de senhas:
                  </p>
                  <div className="flex flex-wrap gap-4 mt-2">
                    {data &&
                      data.category[selectCategory].day &&
                      data.category[selectCategory].day[selectDay].quants.map(
                        (item, index) => (
                          <div className="flex items-center gap-x-4 border border-slate-500 p-4 rounded-md">
                            <IoTicketOutline className="text-lg text-slate-700" />
                            <p className="text-slate-700">
                              {index + 1}º{" "}
                              {index + 1 === 1 ? "Senha" : "Senhas"} -
                              {formatCurrency(item.quant_value)}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </div>

                <div className="flex flex-wrap mt-4 gap-4">
                  {data &&
                    generateList(
                      data.category[selectCategory].day[selectDay]
                        .day_start_number,
                      data.category[selectCategory].day[selectDay]
                        .day_end_number
                    ).map((item, index) => {
                      const isSelected = results.some(
                        (res) =>
                          res.number === item &&
                          res.category.categ_id ===
                            data.category[selectCategory].categ_id &&
                          res.day.day_id ===
                            data.category[selectCategory].day[selectDay].day_id
                      );

                      const hasPassword = data.category[selectCategory].day[
                        selectDay
                      ].passwords.some((exist) => exist.pass_number === item);

                      return (
                        <div
                          key={index}
                          className={`${
                            hasPassword
                              ? "bg-[#FF0000] pointer-events-none"
                              : isSelected
                              ? "bg-blue-primary"
                              : "border border-blue-primary"
                          } flex justify-center items-center w-10 h-10 rounded-md hover:cursor-pointer`}
                          onClick={() =>
                            handleSelectNumber(
                              item,
                              parseInt(id),
                              data.category[selectCategory],
                              data.category[selectCategory].day[selectDay],
                              data.category[selectCategory].day[selectDay]
                                .quants
                            )
                          }
                        >
                          <span
                            className={`${
                              hasPassword
                                ? "text-white"
                                : isSelected
                                ? "text-white"
                                : "text-blue-primary"
                            }`}
                          >
                            {item}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </>
        )}

        {endView && (
          <div className="mt-2">
            <div className="flex justify-end">
              <button
                onClick={() => setEndView(false)}
                className="flex items-center gap-x-2 bg-blue-primary text-zinc-100 px-3 py-1.5 rounded-md"
              >
                <MdOutlineKeyboardBackspace className="text-zinc-100" />
                Voltar
              </button>
            </div>

            <h2 className="text-xl text-slate-800 font-semibold text-left py-2">
              Dados do participante
            </h2>
            <div className="md:flex w-full gap-x-4 mt-4">
              <div className="w-full flex flex-col gap-2">
                <p className="text-slate-800">Nome completo do vaqueiro</p>
                <input
                  onChange={handleChange}
                  value={form.pass_name_people}
                  name="pass_name_people"
                  type="text"
                  placeholder="Nome do vaqueiro"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
                {errors?.pass_name_people && (
                  <span className="text-red-500">
                    {errors.pass_name_people}
                  </span>
                )}
              </div>
              <div className="w-full flex flex-col gap-2 mt-3 md:mt-0">
                <p className="text-slate-800">Nome chamado pela locução</p>
                <input
                  onChange={handleChange}
                  value={form.pass_name_people_presentation}
                  name="pass_name_people_presentation"
                  type="text"
                  placeholder="Apelido do vaqueiro"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
                {errors?.pass_name_people_presentation && (
                  <span className="text-red-500">
                    {errors.pass_name_people_presentation}
                  </span>
                )}
              </div>
            </div>
            <div className="md:flex w-full gap-x-4 mt-4">
              <div className="w-full flex flex-col gap-2">
                <p className="text-slate-800">Cavalo puxador</p>
                <input
                  onChange={handleChange}
                  value={form.pulling_horse}
                  name="pulling_horse"
                  type="text"
                  placeholder="Nome do cavalo puxador"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
                {errors?.pulling_horse && (
                  <span className="text-red-500">{errors.pulling_horse}</span>
                )}
              </div>
              <div className="w-full flex flex-col gap-2">
                <p className="text-slate-800">Esteireiro</p>
                <input
                  onChange={handleChange}
                  value={form.steerer}
                  name="steerer"
                  type="text"
                  placeholder="Nome do esteireiro"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
                {errors?.steerer && (
                  <span className="text-red-500">{errors.steerer}</span>
                )}
              </div>
              <div className="w-full flex flex-col gap-2">
                <p className="text-slate-800">Cavalo esteireiro</p>
                <input
                  onChange={handleChange}
                  value={form.steer_horse}
                  name="steer_horse"
                  type="text"
                  placeholder="Nome do vaqueiro"
                  className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
                />
                {errors?.steer_horse && (
                  <span className="text-red-500">{errors.steer_horse}</span>
                )}
              </div>
            </div>

            <div className="w-full flex flex-col gap-2 mt-4">
              <p className="text-slate-800">Representação</p>
              <input
                onChange={handleChange}
                value={form.representation}
                name="representation"
                type="text"
                placeholder="Digite sua representação"
                className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
              />
              {errors?.representation && (
                <span className="text-red-500">{errors.representation}</span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2 mt-3">
              <p className="text-slate-800">CPF do vaqueiro</p>
              <input
                onChange={handleChange}
                value={form.pass_cpf_people}
                name="pass_cpf_people"
                type="text"
                placeholder="000.000.000-00"
                className="w-full h-12 pl-4 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
              />
              {errors?.pass_cpf_people && (
                <span className="text-red-500">{errors.pass_cpf_people}</span>
              )}
            </div>

            <div className="mt-5">
              <button
                className="flex items-center gap-x-2 bg-green-500 py-2 px-3 rounded-md"
                onClick={onSubmit}
              >
                Ir para o resumo
              </button>
            </div>
          </div>
        )}
      </ViewStyled>
      {results.length > 0 && !endView && (
        <div className="flex w-full border border-[#ace6ff] mt-6 rounded-[10px] p-4">
          <div>
            {results &&
              results.map((item) => (
                <div className="flex items-center gap-x-2 mt-2 pb-2 border-b border-[#ace6ff]">
                  <p className="text-slate-600">
                    Nº {item.number} -{" "}
                    {item.quantity.length > 1
                      ? `${item.quantity.length} Senhas`
                      : `${item.quantity.length} Senha`}{" "}
                    -{" "}
                    {DateData.filter((date) => date.tag === item.day.day_index)
                      .length &&
                      DateData.filter(
                        (date) => date.tag === item.day.day_index
                      )[0].name}{" "}
                    = {calculateQuantity(item.quantity)}
                  </p>
                </div>
              ))}
            {results.length > 0 && (
              <h2 className="text-slate-600 font-medium mt-2">
                Total: R${getTotal()}
              </h2>
            )}
            <div className="mt-4">
              <button
                className="bg-green-500 py-2 px-3 rounded-md"
                onClick={handleProgressSubmit}
              >
                Prosseguir
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="h-6" />
    </>
  );
};

export default HomePage;
