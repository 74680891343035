import { useEffect, useState } from "react";

import { Title } from "../../assets/css/title";
import { MyPasswordsStyled } from "./styled";
import {
  Pagination,
  PaginationInfo,
  PaginationItem,
} from "../../assets/css/pagination";

// ICONS
import { CiUser } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoTicketOutline } from "react-icons/io5";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { allPasswordsType } from "../../types/password";
import { IoMdDownload } from "react-icons/io";
import { useMessage } from "../../services/contexts/MessageContext";
import { useLoading } from "../../services/contexts/LoadingContext";
import { getAllMy } from "../../services/http/passwords/get-all-my";
import { PDFDownloadLink } from "@react-pdf/renderer";

import PDFComprovante from "./pdf";
import { DateData } from "../../configs/data";

const MyPasswordsPage = () => {
  const { addMessage } = useMessage();
  const { statusLoading } = useLoading();
  const [myPasswords, setMyPasswords] = useState<any[]>([]);
  // const [myPasswords, setMyPasswords] = useState<{
  //   pages: number,
  //   current: number,
  //   rows: Array<allPasswordsType>
  // }>({
  //   current: 0,
  //   pages: 0,
  //   rows: []
  // })

  async function getAllMyPasswords() {
    statusLoading(true);
    try {
      const response = await getAllMy();
      console.log(response);
      setMyPasswords(response);
      return;
    } catch (e) {
      addMessage("Erro ao buscar senhas", "error");
      // window.location.href = "/";
      console.log(e);
    } finally {
      statusLoading(false);
    }
  }

  useEffect(() => {
    getAllMyPasswords();
  }, []);

  return (
    <MyPasswordsStyled>
      <Title>Minhas senhas</Title>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>
                <div>NOME</div>
              </th>
              <th>
                <div>CPF</div>
              </th>
              <th>
                <div>NÚMERO</div>
              </th>
              <th>
                <div>CATEGORIA</div>
              </th>
              <th>
                <div>DIA</div>
              </th>
              <th>
                <div>SENHAS</div>
              </th>
              <th>
                <div>VAQUEJADA</div>
              </th>
              <th>
                <div>DATA</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {myPasswords &&
              myPasswords.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div>
                      <CiUser />
                      {item.pass_name_people}
                    </div>
                  </td>
                  <td>
                    <div>
                      <MdOutlineDocumentScanner />
                      {item.pass_cpf_people}
                    </div>
                  </td>
                  <td>
                    <div>
                      <IoTicketOutline /> {item.pass_number}
                    </div>
                  </td>
                  <td>
                    <div>Categ. {item.category.categ_name}</div>
                  </td>
                  <td>
                    <div>
                      {
                        DateData.filter(
                          (day) => day.tag === item.day.day_index
                        )[0].name
                      }
                    </div>
                  </td>
                  <td>
                    <div>
                      {item.quantity_on_passwords.length > 1
                        ? `${item.quantity_on_passwords.length} Senhas`
                        : `${item.quantity_on_passwords.length} Senha`}
                    </div>
                  </td>
                  <td>
                    <div>{item.poster.post_name}</div>
                  </td>
                  <td>
                    <div>{item.poster.post_date}</div>
                  </td>
                  <td>
                    <div style={{ cursor: "pointer" }}>
                      <PDFDownloadLink
                        document={<PDFComprovante datas={item} />}
                        fileName="Comprovante de compra.pdf"
                      >
                        {({ blob, url, loading, error }: any) =>
                          loading ? "Carregando documento..." : <IoMdDownload />
                        }
                      </PDFDownloadLink>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination>
        <PaginationInfo>{myPasswords.rows.length}{' '}senhas encontradas</PaginationInfo>
        <ul>
          <PaginationItem active={myPasswords.current > 1}><IoIosArrowBack /></PaginationItem>
          {
            Array(myPasswords.pages).fill("").map((item, index) => (
              <PaginationItem active={myPasswords.current === (index + 1)} key={index}><p>{index + 1}</p></PaginationItem>
            ))
          }
          <PaginationItem active={myPasswords.current < myPasswords.pages}><IoIosArrowForward /></PaginationItem>
        </ul>
      </Pagination> */}
    </MyPasswordsStyled>
  );
};

export default MyPasswordsPage;
