import logo from "../../assets/images/logo-msenhas-ofcl.png";

export default function PoliticaDePrivacidadePage() {
  return (
    <div className="flex flex-col justify-center items-center w-full  bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center mt-6 mb-6">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          POLÍTICA DE PRIVACIDADE
        </h1>
        <div className="mt-4 px-12">
          <p>
            Seja bem-vindo à MSenhas Tecnologia LTDA! A presente Política de Uso
            tem como objetivo esclarecer as diretrizes para o uso de nossa
            plataforma, garantindo a segurança, transparência e conformidade com
            as legislações vigentes. Ao utilizar nossos serviços, você concorda
            com os termos aqui estabelecidos.
          </p>

          <div className="mt-4">
            <span className="text-lg font-bold">1. Definições</span>
            <p className="mt-4">
              • Usuário: Pessoa física ou jurídica que acessa a plataforma
              MSenhas, seja para adquirir ou gerenciar senhas para eventos.
            </p>
            <p className="mt-4">
              • Organizador: Pessoa física ou jurídica responsável pela criação
              e gerenciamento de eventos na plataforma.
            </p>
            <p className="mt-4">
              • Dados Pessoais: Informações relacionadas à identidade do
              usuário, como nome, CPF, e-mail e telefone.
            </p>
            <p className="mt-4">
              • Dados Não Pessoais: Informações que não permitem a identificação
              do usuário, como padrões de navegação e preferências.
            </p>
            <p className="mt-4">
              • Cookies: Arquivos armazenados no dispositivo do usuário para
              melhorar a experiência de navegação e fornecer funcionalidades
              personalizadas.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">
              2. Coleta e Tratamento de Dados
            </span>
            <p className="mt-4">
              2.1. A MSenhas coleta informações fornecidas diretamente pelo
              usuário, bem como dados gerados automaticamente durante o uso da
              plataforma, respeitando a Lei nº 13.709/2018 (Lei Geral de
              Proteção de Dados - LGPD).
            </p>
            <p className="mt-4">
              2.2. Os dados coletados podem incluir: nome, CPF, telefone,
              e-mail, endereço, dados financeiros (para transações) e
              informações de acesso.
            </p>
            <p className="mt-4">
              2.3. A MSenhas se compromete a utilizar os dados pessoais
              estritamente para as finalidades descritas nesta política,
              garantindo sua segurança e confidencialidade.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">3. Uso das Informações</span>
            <p className="mt-4">3.1. Os dados coletados são utilizados para:</p>
            <p className="mt-4">• Facilitar a compra e gestão de senhas;</p>
            <p>• Melhorar a experiência do usuário na plataforma;</p>
            <p>• Cumprir obrigações legais e regulatórias;</p>
            <p>
              • Realizar comunicações institucionais e promocionais, respeitando
              a legislação vigente.
            </p>
            <p className="mt-4">
              3.2. O usuário pode gerenciar suas preferências de comunicação a
              qualquer momento através dos canais disponibilizados pela MSenhas.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">
              4. Compartilhamento de Dados
            </span>
            <p className="mt-4">
              4.1. A MSenhas não vende ou comercializa dados pessoais. No
              entanto, pode compartilhá-los com:
            </p>
            <p className="mt-4">
              • Prestadores de serviços que auxiliam na operação da plataforma;
            </p>
            <p>
              • Autoridades competentes, quando exigido por lei ou decisão
              judicial;
            </p>
            <p>• Cumprir obrigações legais e regulatórias;</p>
            <p>• Empresas parceiras, mediante consentimento do usuário.</p>
            <p className="mt-4">
              4.2. Sempre que possível, os dados serão anonimizados antes do
              compartilhamento.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">
              5. Armazenamento e Segurança
            </span>
            <p className="mt-4">
              5.1. A MSenhas adota medidas técnicas e organizacionais para
              proteger os dados dos usuários contra acessos não autorizados,
              perdas ou vazamentos
            </p>
            <p className="mt-4">
              5.2. Os dados são armazenados pelo tempo necessário para cumprir
              suas finalidades ou conforme exigido por lei.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">6. Direitos do Usuário</span>
            <p className="mt-4">6.1. Conforme a LGPD, o usuário pode:</p>
            <p className="mt-4">• Solicitar acesso aos seus dados;</p>
            <p>• Corrigir informações incorretas ou desatualizadas;</p>
            <p>
              • Solicitar a exclusão de seus dados (exceto quando necessários
              para cumprimento legal);
            </p>
            <p>• Revogar consentimentos previamente concedidos.</p>
            <p className="mt-4">
              6.2. Para exercer seus direitos, o usuário pode entrar em contato
              através do e-mail contato@msenhas.com.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">7. Disposições Gerais</span>
            <p className="mt-4">
              7.1. A MSenhas se reserva o direito de modificar esta Política de
              Uso a qualquer momento, notificando os usuários conforme
              aplicável.
            </p>
            <p className="mt-4">
              7.2. A utilização contínua da plataforma após alterações na
              política implica na aceitação dos novos termos.
            </p>
            <p>
              7.3. Sempre que possível, eventuais conflitos serão resolvidos de
              forma amigável. Caso necessário, fica eleito o foro da Comarca de
              Montes Claros/MG para dirimir qualquer questão relacionada a esta
              política.
            </p>
          </div>

          <div className="mt-4">
            <span className="text-lg font-bold">8. Atendimento ao Usuário</span>
            <p className="mt-4">
              8.1. Para dúvidas, solicitações ou suporte técnico, os usuários
              podem entrar em contato pelos seguintes meios:
            </p>
            <p className="mt-4">• E-mail: contato@msenhas.com</p>
            <p>• Horário de atendimento: 24 horas, todos os dias da semana</p>
          </div>

          <p className="mt-4">
            Responsável Legal: Charliton Almeida Mendes Junior.
          </p>
          <p className="mt-4">
            Para mais informações, entre em contato pelo e-mail:
            contato@msenhas.com.
          </p>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100"></div>
    </div>
  );
}
