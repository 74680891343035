import logo from "../../assets/images/logo-msenhas-ofcl.png";

export default function ComoFuncionaPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center mt-6 mb-6">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          COMO FAZER SUA SENHA
        </h1>
        <div className="mt-4 px-12">
          <p>
            1. Primeiro, cadastre-se em nosso site clicando no botão "Cadastrar"
            e preenchendo o formulário com seus dados.
            <div className="py-2" />
            2. Em seguida, clique em "Ver Vaquejadas" e procure a vaquejada na
            qual deseja fazer sua senha. Depois, escolha a categoria e o dia em
            que deseja competir. Lembre-se de que a categoria será julgada pelo
            dono da vaquejada.
            <div className="py-2" />
            3. Verifique a quantidade de senhas permitidas por categoria e, em
            seguida, escolha os números das suas senhas, selecionando quantos
            forem permitidos por senha. Após isso, clique em "Prosseguir".
            <div className="py-2" />
            4. Na próxima etapa, preencha as informações da sua senha com
            atenção e clique em "Prosseguir" novamente.
            <div className="py-2" />
            5. Por fim, escolha o método de pagamento de sua preferência, efetue
            o pagamento da sua senha e... boa vaquejada!
          </p>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Como vejo as senhas que eu fiz?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Clique em "Minhas Senhas". Lá, você encontrará um resumo
                completo das suas senhas.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Como faço para mudar os dados da minha senha?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Entre em contato com nosso suporte e solicite as alterações.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Quanto tempo tenho para pagar minha senha?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Nosso sistema define um prazo padrão de 1 hora para pagamento.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso fazer uma senha com mais de um método de pagamento?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Não. Você pode fazer uma senha apenas com um método de
                pagamento. Caso queira utilizar dois métodos de pagamento, será
                necessário comprar duas senhas separadamente.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso pagar com cartão de crédito?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Sim! Você pode parcelar em até 12 vezes com juros.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso pagar com cartão de crédito?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Pode sim! E ainda parcela em até 12x com juros. Usando o cartão
                de crédito sua compra é aprovada em até 3 segundos.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Como comprar meu boi de TV?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Após comprar sua senha, você receberá um link que o
                redirecionará para o ambiente de compra. O boi de TV será
                adicionado à sua senha até o final do prazo de compra das
                senhas.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Posso comprar para outro vaqueiro no meu cadastro?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Sim! Basta informar os dados do vaqueiro no momento da compra.
              </p>
            </div>
          </details>
        </div>
        <div className="mt-4 px-12">
          <details>
            <summary className="bg-gray-200 p-4 rounded-md cursor-pointer">
              Estou com dificuldade para fazer minha senha. O que faço?
            </summary>
            <div className="bg-gray-100 p-4 mt-2 rounded-md">
              <p className="text-gray-600">
                Entre em contato com nosso suporte online e peça toda a
                orientação necessária. Estamos aqui para oferecer o melhor
                suporte para você!
              </p>
            </div>
          </details>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100" />
    </div>
  );
}
