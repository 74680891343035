import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/button";
import { useMessage } from "../../services/contexts/MessageContext";
import { StepOne } from "./components/step-one";
import { StepTwo } from "./components/step-two";
import { FaChevronLeft } from "react-icons/fa";
import { getItem } from "../../services/storage/storage";
import { createPaymentCreditCard } from "../../services/http/payment/create-payment-credit-card";
import { createMultiplePassword } from "../../services/http/passwords/get-all-my";
import { getMeProfile } from "../../services/http/users/get-me-profile";

const CheckoutCreditCardPage = () => {
  const navigate = useNavigate();
  const { addMessage } = useMessage();
  const [firstStep, setFirstStep] = useState(true);
  const [total, setTotal] = useState<number>();
  const [quantities, setQuantities] = useState<any[]>([]);
  const [profile, setProfile] = useState<any>(null);
  const [processPayment, setProcessPayment] = useState(false);

  const passwordData: any = getItem("@password-data");

  const [firstForm, setFirstForm] = useState({
    name: "",
    cpf: "",
    email: "",
    zip_code: "",
    street: "",
    city: "",
    neighborhood: "",
    number: "",
    state: "",
  });

  const [secondForm, setSecondForm] = useState({
    cardNumber: "",
    cardName: "",
    expiryDate: "",
    cvv: "",
    installments: "1",
  });

  async function getProfile() {
    try {
      const response = await getMeProfile();
      setProfile(response);
      return;
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCreatePassword() {
    try {
      const response = await createMultiplePassword(passwordData);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleFinishPayment() {
    setProcessPayment(true);

    const area = profile?.usr_phone.substring(0, 2);
    const number = profile?.usr_phone.substring(2);

    const itemsWithFee = [
      ...quantities.map((item) => ({
        amount: item.quant_value * 100,
        description: "Senha(s)",
        quantity: 1,
        code: Number(Math.floor(Math.random() * 900) + 100),
      })),
      {
        amount: 400,
        description: "Taxa Administrativa",
        quantity: 1,
        code: Number(Math.floor(Math.random() * 900) + 100),
      },
    ];

    const body = {
      items: itemsWithFee,
      customer: {
        name: firstForm.name,
        type: "individual",
        email: firstForm.email,
        document: firstForm.cpf.replace(/\D/g, ""),
        phones: {
          mobile_phone: {
            country_code: "55",
            area_code: area,
            number: number,
          },
        },
      },
      payments: [
        {
          payment_method: "credit_card",
          credit_card: {
            recurrence_cycle: "first",
            installments: String(secondForm.installments),
            statement_descriptor: "MSENHAS LTDA",
            card: {
              number: secondForm.cardNumber.replace(/\D/g, ""),
              holder_name: secondForm.cardName,
              exp_month: Number(
                secondForm.expiryDate.substring(0, 2).startsWith("0")
                  ? secondForm.expiryDate.substring(1, 2)
                  : secondForm.expiryDate.substring(0, 2)
              ),
              exp_year: Number(secondForm.expiryDate.substring(3, 5)),
              cvv: secondForm.cvv,
              billing_address: {
                line_1: `${firstForm.number}, ${firstForm.street}, ${firstForm.neighborhood}`,
                zip_code: firstForm.zip_code.replace(/\D/g, ""),
                city: firstForm.city,
                state: firstForm.state,
                country: "BR",
              },
            },
          },
        },
      ],
    };
    try {
      const response = await createPaymentCreditCard(body);
      if (response.status === "paid") {
        handleCreatePassword();
        navigate(`/payment-status/${response.status}`);
      } else {
        addMessage("O pagamento falhou.", "error");
      }
      return;
    } catch (error) {
      console.log(error);
      addMessage("Houve um erro com o pagamento.", "error");
    } finally {
      setProcessPayment(false);
    }
  }

  function calculateTotal(
    quantity: Array<{ quant_id: number; quant_value: number }>
  ) {
    let value = 0;

    quantity.forEach((element) => {
      value += element.quant_value;
    });

    setTotal(value);
  }

  useEffect(() => {
    if (passwordData) {
      getProfile();
      const quantities = passwordData.passwords.flatMap((p: any) =>
        p.quantity.map((q: any) => q)
      );
      setQuantities(quantities);
      calculateTotal(quantities);
    }
  }, []);

  return (
    <div className="border border-[#ace6ff] bg-white -mt-[4em] rounded-[10px] p-4">
      <Button onClick={() => navigate("/resume")}>
        <div className="w-full flex justify-center items-center gap-x-2">
          <FaChevronLeft className="text-white" />
          <span className="text-white">Resumo</span>
        </div>
      </Button>
      {firstStep ? (
        <StepOne
          firstForm={firstForm}
          setFirstForm={setFirstForm}
          addMessage={addMessage as any}
          setFirstStep={setFirstStep}
        />
      ) : (
        <StepTwo
          secondForm={secondForm}
          setSecondForm={setSecondForm}
          addMessage={addMessage as any}
          setFirstStep={setFirstStep}
          loading={processPayment}
          total={total ? total + 4 : 0}
          handleFinishPayment={handleFinishPayment}
        />
      )}
    </div>
  );
};

export default CheckoutCreditCardPage;
