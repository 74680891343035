import styled from "styled-components";

export const CheckoutStyled = styled("article")`
  border: solid 1px ${({ theme }) => theme.border.primary_opacy};
  padding: 2em;
  margin-top: -4em;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: 10px;
  .error-notification {
    display: flex;
    flex-direction: column;
    li {
      background-color: #ff9e9e;
      padding: 20px;
      margin-bottom: 10px;
      color: #000;
      border-radius: 10px;
    }
  }
  .boxed-informations {
    display: flex;
    /* margin-top: 20px; */
    .boxedPaiment {
      .pix {
        border: solid 1px ${({ theme }) => theme.border.opacity};
        border-radius: 20px;
        padding: 20px 30px;
        .qrcode {
          padding: 0 30px;
          img {
            height: 270px;
          }
        }
        .boxed {
          margin-top: 3em;
          display: flex;
          flex-direction: column;
          width: 100%;
          p {
            color: ${({ theme }) => theme.color.primary2};
            padding: 0 0 10px 0;
          }
        }
        .boxed_btn {
          margin-top: 1em;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    /* .info {
      h2 {
        color: ${({ theme }) => theme.color.secondary};
      }
      .informations {
        display: flex;
        margin-top: 2em;
        li {
          margin-right: 20px;
          .title {
            color: ${({ theme }) => theme.color.primary2};
            padding: 0 0 10px 0;
          }
          .content {
            color: ${({ theme }) => theme.color.primary3};
            padding: 0 0 10px 0;
          }
        }
      }
      .passwords {
        margin-top: 2em;
        .title {
          color: ${({ theme }) => theme.color.primary2};
          padding: 0 0 10px 0;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          div {
            margin: 5px;
          }
        }
      }
      .observations {
        margin-top: 2em;
        border: solid 1px ${({ theme }) => theme.border.opacity};
        color: ${({ theme }) => theme.color.primary2};
        padding: 20px;
        border-radius: 20px;
        li {
          list-style-type: circle;
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }
    } */
  }

  @media (max-width: 1000px) {
    .boxed-informations {
      flex-direction: column;
      align-items: center;
    }
  }
`;
