import React, { useEffect, useState } from "react";
import { ProfileStyled, WrapperButtons } from "./profile.styled";

// ICONES
import { IoIosArrowForward } from "react-icons/io";

// IMAGESsa
import logo from "../../assets/images/logo-msenhas-ofcl.png";

import { ButtonLink } from "../../assets/css/button";
import { Link } from "react-router-dom";
import { FaTicketAlt } from "react-icons/fa";
import { useAuth } from "../../services/contexts/AuthContext";

import { RxExit } from "react-icons/rx";

const ProfileMenu: React.FC = () => {
  const [openClose, setOpenClose] = useState(false);
  const [auth, setAuth] = useState(false);
  const { isLoggedIn, logout } = useAuth();

  useEffect(() => {
    async function checkAuthStatus() {
      const loggedIn = await isLoggedIn();
      setAuth(loggedIn);
    }

    checkAuthStatus();
  }, []);

  return (
    <ProfileStyled>
      <li>
        <Link to="/">
          <img
            className="logo"
            src={logo}
            alt="logo icone"
            style={{ height: 40 }}
          />
        </Link>
      </li>
      {!auth ? (
        <WrapperButtons>
          <ButtonLink
            to="/auth/register"
            margin="0 10px 0 0"
            border="primary"
            backgroundColor="secondary"
            color="secondary"
          >
            Registrar-se
          </ButtonLink>
          <ButtonLink
            to="/auth/login"
            border="light"
            backgroundColor="primary"
            color="light"
          >
            Login
            <IoIosArrowForward className="ml-1" />
          </ButtonLink>
        </WrapperButtons>
      ) : (
        <li>
          <ButtonLink
            to="/my-passwords"
            margin="0 10px 0 0"
            border="primary"
            backgroundColor="secondary"
            color="secondary"
          >
            Minhas senhas
            <FaTicketAlt style={{ marginLeft: "10px" }} />
          </ButtonLink>
          <div className="people">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW8vqrf4s692AjEZQEAaab2aSLXRHsB3A9aykbqEZ5yk-LdoG6RzjtzCNOIstLcwTcTl4&usqp=CAU"
              alt="profile image"
              onClick={() => setOpenClose(!openClose)}
            />
            {openClose && (
              <ul>
                <li onClick={logout}>
                  <RxExit />
                  <p>Sair</p>
                </li>
              </ul>
            )}
          </div>
        </li>
      )}
    </ProfileStyled>
  );
};

export default ProfileMenu;
