// ICONES
import { RegisterStyled, RegisterCardStyled } from "./styled";

import logo from "../../../assets/images/logo-msenhas-ofcl.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CreateUser } from "../../../types/user";
import { useAuth } from "../../../services/contexts/AuthContext";
import {
  RemoveCaracteres,
  formatCPF,
  formatPhone,
} from "../../../services/utils/format";
import {
  compareText,
  completeMinLength,
  required,
  validateEmail,
} from "../../../services/utils/validation";
import { useMessage } from "../../../services/contexts/MessageContext";
import { useLoading } from "../../../services/contexts/LoadingContext";
import { createUser } from "../../../services/http/users/create-user";

const RegisterPage = () => {
  const { login } = useAuth();
  const { statusLoading } = useLoading();
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleNavigate() {
    navigate("/auth/login");
  }

  const Submit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const newCPF = RemoveCaracteres(cpf);
    const newPhone = RemoveCaracteres(phone);

    console.log(newCPF);

    if (!required(name)) {
      return addMessage("Nome é obrigatório", "error");
    }

    if (!completeMinLength(name, 3)) {
      return addMessage("Nome deve ter pelo menos 3 caracteres", "error");
    }

    if (!required(newCPF)) {
      return addMessage("CPF é obrigatório", "error");
    }

    if (!completeMinLength(newCPF, 11)) {
      return addMessage("CPF deve ter pelo menos 11 digitos", "error");
    }

    if (!required(password)) {
      return addMessage("Senha é obrigatório", "error");
    }

    if (!completeMinLength(password, 8)) {
      return addMessage("Senha deve ter pelo menos 8 caracteres", "error");
    }

    if (!required(confirmPassword)) {
      return addMessage("Confirmação de senha é obrigatório", "error");
    }

    if (!compareText(password, confirmPassword)) {
      return addMessage(
        "Senha e confirmação de senha devem ser iguais",
        "error"
      );
    }

    if (!required(email)) {
      return addMessage("E-mail é obrigatório", "error");
    }

    if (!validateEmail(email)) {
      return addMessage("E-mail inválida", "error");
    }

    if (!required(phone)) {
      return addMessage("Telefone é obrigatório", "error");
    }

    if (!completeMinLength(newPhone, 11)) {
      return addMessage("Telefone deve ter pelo menos 11 digitos", "error");
    }

    if (!required(date)) {
      return addMessage("Data de nascimento é obrigatório", "error");
    }

    try {
      statusLoading(true);
      const data: CreateUser = {
        usr_cpf: newCPF,
        usr_password: password,
        usr_name: name,
        usr_birth: date,
        usr_email: email,
        usr_phone: newPhone,
        usr_image: "https://i.ibb.co/C5Dv7jY/1.png",
        usr_type: "CLIENT",
      };
      await createUser(data);
      await login({
        cpf: data.usr_cpf,
        password: data.usr_password,
      });
      statusLoading(false);
      window.location.href = "/";
    } catch (e: any) {
      statusLoading(false);
      if (e.code === "ERR_BAD_REQUEST") {
        return addMessage("Já existe uma conta com esse CPF", "error");
      } else {
        console.log(e);
        return addMessage("Erro ao criar conta", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterStyled>
      <header></header>
      <section>
        <RegisterCardStyled onSubmit={Submit}>
          <div className="boxedImage">
            <img src={logo} alt="" width={120} />
          </div>
          <div className="mt-6">
            <h2 className="text-xl text-slate-800 font-medium">Registrar</h2>
            <p className="text-slate-800 mt-2">
              Registre-se para gerir e comprar senhas.
            </p>
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-10 placeholder:text-slate-600"
              type="text"
              placeholder="Nome"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="text"
              placeholder="CPF"
              onChange={(e) => setCpf(formatCPF(e.target.value))}
              value={cpf}
            />
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="password"
              placeholder="Senha"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="password"
              placeholder="Confirmar senha"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="email"
              placeholder="E-mail"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <span></span>
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="text"
              placeholder="Telefone"
              onChange={(e) => setPhone(formatPhone(e.target.value))}
              value={phone}
            />
          </div>
          <div className="boxed">
            <input
              className="py-3 px-4 border border-[#0082BA] text-slate-700 focus:outline-none rounded-md mt-3 placeholder:text-slate-600"
              type="date"
              placeholder="Data de nascimento"
              onChange={(e) => setDate(e.target.value)}
              value={date}
            />
          </div>

          <div className="boxed">
            <button
              type="submit"
              className="w-full py-3 bg-[#0082BA] mt-8 rounded-md"
            >
              {isLoading ? (
                <div className="flex justify-center items-center gap-x-3">
                  Registrando
                  <div className="w-5 h-5 border-4 border-white border-t-[#3498db] rounded-full animate-spin" />
                </div>
              ) : (
                "Registrar"
              )}
            </button>
          </div>
          <div className="boxedOptions">
            <button
              className="w-full py-3 border border-[#0082BA] text-[#0082BA] rounded-md"
              onClick={handleNavigate}
            >
              Voltar ao login
            </button>
          </div>
        </RegisterCardStyled>
      </section>
    </RegisterStyled>
  );
};

export default RegisterPage;
