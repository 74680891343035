import logo from "../../assets/images/logo-msenhas-ofcl.png";

export default function QuemSomosPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full sm:h-screen bg-zinc-100">
      <div className="w-[80%] my-24 pb-8 shadow-md bg-white rounded-md">
        <div className="flex justify-center items-center mt-6 mb-6">
          <img src={logo} alt="" width={138} />
        </div>
        <h1 className="text-center text-3xl font-medium text-slate-800 mt-4">
          QUEM SOMOS
        </h1>
        <div className="mt-4 px-12">
          <span>
            A <strong>MSENHAS TECNOLOGIA LTDA</strong> é uma empresa
            especializada na venda de <strong>senhas antecipadas</strong> para
            vaquejadas de qualquer porte. Nosso sistema foi criado para tornar o
            processo de compra de senhas{" "}
            <strong>rápido, prático e eficiente</strong>, permitindo que o
            vaqueiro garanta sua participação com apenas alguns cliques.
          </span>
        </div>
        <div className="mt-4 px-12">
          <span>
            Mais do que um simples sistema, somos uma empresa que{" "}
            <strong>entende a realidade do vaqueiro</strong>. Nosso compromisso
            é oferecer uma experiência intuitiva, segura e ágil, sempre buscando
            melhorias para atender às necessidades do competidor da melhor forma
            possível.
          </span>
        </div>
        <div className="mt-4 px-12">
          <span>
            Para os <strong>donos de vaquejada</strong>, garantimos{" "}
            <strong>transparência total</strong> no processo. Nosso sistema
            permite que o organizador acompanhe <strong>em tempo real</strong>{" "}
            todas as informações da sua vaquejada, facilitando a gestão e
            atraindo mais competidores para o evento.
          </span>
        </div>
        <div className="mt-4 px-12">
          <span>
            É importante destacar que a{" "}
            <strong>MSENHAS TECNOLOGIA LTDA não realiza vaquejadas</strong>.
            Somos uma empresa <strong>terceirizada</strong>, contratada pelos
            organizadores para intermediar a venda antecipada de senhas. Nossa
            atuação se limita à comercialização de senhas através do nosso
            sistema, e não realizamos atividades de secretaria durante o evento.
          </span>
        </div>
        <div className="mt-4 px-12">
          <span>
            Nosso compromisso é com a inovação e a melhoria contínua, garantindo
            que tanto o vaqueiro quanto o organizador tenham a{" "}
            <strong>melhor experiência possível</strong> no processo de compra e
            gestão das senhas.
          </span>
        </div>
        <div className="mt-4 px-12">
          <strong>
            MSENHAS TECNOLOGIA LTDA - Simplificando sua senha, fortalecendo a
            vaquejada!
          </strong>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100"></div>
    </div>
  );
}
