import { ButtonLink } from "../../assets/css/button";

import {
  HomeHeaderH1Styled,
  HomeHeaderActionsStyled,
  HomeHeaderPagraphStyled,
} from "./styled";

export default function HomeHeaderPage() {
  return (
    <>
      <HomeHeaderH1Styled>
        Melhor sistema de gerenciamento de
        <br /> senhas atencipadas do Brasil.
      </HomeHeaderH1Styled>
      <HomeHeaderPagraphStyled>
        Gerencie suas senhas em um ambiente digital.
      </HomeHeaderPagraphStyled>
      <HomeHeaderActionsStyled>
        <ButtonLink
          to="/events"
          margin="0 10px 0 0"
          border="secondary"
          backgroundColor="secondary"
          color="secondary"
        >
          Ver vaquejadas
        </ButtonLink>
        {/* <ButtonLink to="/" border="secondary" backgroundColor="primary" color="primary">
          Cadastrar meu evento <GiHamburgerMenu className="ml-1" />
        </ButtonLink> */}
      </HomeHeaderActionsStyled>
    </>
  );
}
