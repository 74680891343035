import styled from "styled-components";
import { Link } from "react-router-dom";

type ButtonType = {
  border: "primary" | "secondary" | "success" | "light";
  color: "primary" | "secondary" | "dark" | "light";
  backgroundColor: "primary" | "secondary" | "success";
  margin?: string;
};

export const Button = styled("button")<ButtonType>`
  border: solid 1px ${(item) => item.theme.border[item.border]};
  background-color: ${(item) => item.theme.background[item.backgroundColor]};
  color: ${(item) => item.theme.color[item.color]};
  margin: ${(item) => item.margin};
  padding: 9px 18px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Info = styled("div")<ButtonType>`
  border: solid 1px ${(item) => item.theme.border[item.border]};
  background-color: ${(item) => item.theme.background[item.backgroundColor]};
  color: ${(item) => item.theme.color[item.color]};
  margin: ${(item) => item.margin};
  padding: 4px 18px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  svg {
    margin: 0 5px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    padding: 8px 16px;
  }

  @media (max-width: 362px) {
    font-size: 13px;
    padding: 7px 14.5px;
  }
`;

export const ButtonLink = styled(Link)<ButtonType>`
  border: solid 1px ${(item) => item.theme.border[item.border]};
  background-color: ${(item) => item.theme.background[item.backgroundColor]};
  color: ${(item) => item.theme.color[item.color]};
  margin: ${(item) => item.margin};
  padding: 8px 18px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 425px) {
    font-size: 14px;
    padding: 11.5px 16px;
  }

  @media (max-width: 362px) {
    font-size: 13px;
    padding: 9.5 14.5px;
  }
`;
