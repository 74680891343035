import { ChangeEvent, useState } from "react";
import { formatToCEP, formatToCPF } from "brazilian-values";
import { FaChevronRight } from "react-icons/fa";
import Button from "../../../components/Button/button";

interface StepOneProps {
  firstForm: {
    name: string;
    cpf: string;
    email: string;
    zip_code: string;
    street: string;
    city: string;
    neighborhood: string;
    number: string;
    state: string;
  };
  setFirstForm: React.Dispatch<React.SetStateAction<StepOneProps["firstForm"]>>;
  setFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  addMessage: (message: string, type: "error" | "success" | "info") => void;
}

const Bollet = () => {
  return <div className="w-1.5 h-1.5 rounded-full bg-red-800" />;
};

export function StepOne({
  firstForm,
  setFirstForm,
  setFirstStep,
  addMessage,
}: StepOneProps) {
  const [errors, setErrors] = useState<any>();

  function validationForm() {
    let errors_list = {};

    if (!firstForm.name) {
      errors_list = { ...errors_list, name: "Nome é obrigatório" };
    }

    if (!firstForm.cpf) {
      errors_list = { ...errors_list, cpf: "CPF é obrigatório" };
    }

    if (!firstForm.email) {
      errors_list = { ...errors_list, email: "E-mail é obrigatório" };
    }

    if (!firstForm.zip_code) {
      errors_list = { ...errors_list, zip_code: "CEP é obrigatório" };
    }

    if (!firstForm.street) {
      errors_list = { ...errors_list, street: "Logradouro é obrigatório" };
    }

    if (!firstForm.city) {
      errors_list = { ...errors_list, city: "Cidade é obrigatório" };
    }

    if (!firstForm.neighborhood) {
      errors_list = { ...errors_list, neighborhood: "Bairro é obrigatório" };
    }

    if (!firstForm.number) {
      errors_list = { ...errors_list, number: "Número é obrigatório" };
    }

    if (!firstForm.state) {
      errors_list = { ...errors_list, state: "Estado é obrigatório" };
    }

    setErrors(errors_list);
    return errors_list;
  }

  function handleNextForm() {
    const validate = validationForm();

    if (!(Object.keys(validate).length === 0)) {
      addMessage("Alguns errors foram encontrados", "error");
      return;
    }

    setFirstStep(false);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputName === "zip_code") {
      inputValue = formatToCEP(inputValue);
    }

    if (inputName === "cpf") {
      inputValue = formatToCPF(inputValue);
    }

    setFirstForm({ ...firstForm, [inputName]: inputValue });
  }

  return (
    <>
      <div className="mt-4" />
      <h3 className="text-lg font-medium text-slate-800">Identificação</h3>
      <form className="mt-4" action="">
        <div className="flex flex-col sm:flex-row gap-6">
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">Nome Completo</label>
              <Bollet />
            </div>
            <input
              value={firstForm.name}
              onChange={handleChange}
              name="name"
              type="text"
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
            />
            {errors?.name && (
              <span className="mt-8 text-red-500">{errors.name}</span>
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">CPF</label>
              <Bollet />
            </div>
            <input
              value={firstForm.cpf}
              onChange={handleChange}
              name="cpf"
              type="text"
              maxLength={14}
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
            />
            {errors?.cpf && (
              <span className="mt-8 text-red-500">{errors.cpf}</span>
            )}
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="flex items-center gap-x-1">
            <label className="text-zinc-800">E-mail</label>
            <Bollet />
          </div>
          <input
            value={firstForm.email}
            onChange={handleChange}
            name="email"
            type="text"
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
          />
          {errors?.email && (
            <span className="mt-8 text-red-500">{errors.email}</span>
          )}
        </div>

        <h3 className="text-lg font-medium mt-4 text-slate-800">Cobrança</h3>
        <div className="w-full mt-4">
          <div className="flex items-center gap-x-1">
            <label className="text-zinc-800">CEP</label>
            <Bollet />
          </div>
          <input
            value={firstForm.zip_code}
            onChange={handleChange}
            name="zip_code"
            type="text"
            maxLength={9}
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
          />
          {errors?.zip_code && (
            <span className="mt-8 text-red-500">{errors.zip_code}</span>
          )}
        </div>
        <div className="w-full mt-4">
          <div className="flex items-center gap-x-1">
            <label className="text-zinc-800">Logradouro</label>
            <Bollet />
          </div>
          <input
            value={firstForm.street}
            onChange={handleChange}
            name="street"
            type="text"
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
          />
          {errors?.street && (
            <span className="mt-8 text-red-500">{errors.street}</span>
          )}
        </div>
        <div className="flex flex-col sm:flex-row gap-6 mt-4">
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">Cidade</label>
              <Bollet />
            </div>
            <input
              value={firstForm.city}
              onChange={handleChange}
              name="city"
              type="text"
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
            />
            {errors?.city && (
              <span className="mt-8 text-red-500">{errors.city}</span>
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">Bairro</label>
              <Bollet />
            </div>
            <input
              value={firstForm.neighborhood}
              onChange={handleChange}
              name="neighborhood"
              type="text"
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
            />
            {errors?.neighborhood && (
              <span className="mt-8 text-red-500">{errors.neighborhood}</span>
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center gap-x-1">
              <label className="text-zinc-800">Número</label>
              <Bollet />
            </div>
            <input
              value={firstForm.number}
              onChange={handleChange}
              name="number"
              type="text"
              className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
            />
            {errors?.number && (
              <span className="mt-8 text-red-500">{errors.number}</span>
            )}
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="flex items-center gap-x-1">
            <label className="text-zinc-800">Estado</label>
            <Bollet />
          </div>
          <input
            value={firstForm.state}
            onChange={handleChange}
            name="state"
            type="text"
            maxLength={2}
            className="w-full h-12 pl-4 mt-1 rounded-md border border-[#0082BA] text-slate-600 outline-none placeholder:text-slate-600"
          />
          {errors?.state && (
            <span className="mt-8 text-red-500">{errors.state}</span>
          )}
        </div>
      </form>
      <div className="flex justify-end mt-4">
        <Button onClick={handleNextForm}>
          <div className="w-full flex justify-center items-center gap-x-2">
            <span className="text-white">Avançar</span>
            <FaChevronRight className="text-white" />
          </div>
        </Button>
      </div>
    </>
  );
}
