import { createGlobalStyle } from "styled-components";

export const GlobalComponent = createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
        font-family: "Roboto", sans-serif;
    }
    li {
        list-style-type: none;
    }
    a {
        text-decoration: none;
    }
    .ml-1 {
        margin-left: .5em;
    }
    .d-flex {
        display: flex;
    }
`;
